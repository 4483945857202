import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import CargoShip from 'images/Cargo2.jpg';
import TimeImageSource from 'images/Time.jpeg';
import WarehouseImageSource from 'images/Warehouse.jpg';
import TruckImageSource from 'images/Truck.jpg';
import CargoImageSource from 'images/featured-image/Cargo2.jpg';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import OptinBlog from 'components/pages/OptinBlog';
import BlogSidebar from 'components/blog/BlogSidebar';
import { PostsWrapper } from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, BottomShare, SectionFull, OptinSection} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query ImgBlog1Query {
      file(relativePath: { eq: "mountains.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="wing of an airplane above the mountains"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="How Shipkoo Utilizes JIT Techniques and Maximizes Lead Time"
        title={intl.formatMessage({ id: "blog-post-meta-title-how-shipkoo-utilizes" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "With JIT technique, Shipkoo ensures that companies get their orders quickly within the lowest time & cost possible. Find out how they are making it possible.",
            },
            {
              property: `og:title`,
              // content: "How Shipkoo Utilizes JIT Techniques and Maximizes Lead Time",
              content: intl.formatMessage({ id: "blog-post-meta-title-how-shipkoo-utilizes" }),
            },
            {
              property: `og:description`,
              content: "With JIT technique, Shipkoo ensures that companies get their orders quickly within the lowest time & cost possible. Find out how they are making it possible.",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              content: CargoShip,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="how-shipkoo-utilizes-jit-techniques-and-maximizes-lead-time"
        title="How Shipkoo Utilizes JIT Techniques and Maximizes Lead Time"
        date="2019-11-29"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              What is JIT? This is an inventory method that controls the movement of products,
              materials, or components by delivering them when the organization needs them. This
              philosophy is mostly used by companies to ensure that parts and materials are supplied the
              very moment they are required in the factory during the production process. Shipkoo
              employees this philosophy by using Shipkoo’s eCommerce origin fulfillment model which does
              not only enhance cost-effectiveness but also saves time. This is applied by Shipkoo by
              sending shipment directly from the fulfillment center in China immediately when the order
              is received.
            </p>

            <Image src={TimeImageSource} alt="Running Time" />

            <p>
              The principle of JIT system involves total quality control, elimination of waste, and
              people involvement. Besides, it integrates JIT inventory management principles which are
              essential in ensuring that the organization reduces inventory and improves operations
              within the organization. some of these principles include:
            </p>
            <ul>
              <li>Reduce buffer inventory</li>
              <li>Improve material handling</li>
              <li>Reduce lot size and increase the frequency of order</li>
              <li>Seek reliability</li> <li>And seek zero inventory</li>
            </ul>

            <p>
              The above principles are related to Shipkoo as it deals with the logistics industry and
              they are applied to achieve the main objectives of the company which includes: lowering
              inventory risk, lower duties, lower the cost of pick and pack, and fast deliveries through
              express shipping.
            </p>

            <Image src={WarehouseImageSource} alt="cargo warehouse" />

            <p>
              Through the integration of the JIT philosophy, the company is in a position to achieve
              numerous implications in the execution of logistics services to its client: transportation
              becomes a vital component and communication becomes more effective due to the effective
              flow of goods. Since the materials, components, and products are transported in the very
              moment they are needed, the company becomes capable of minimizing the obsolescent of the
              items that can become obsolete anytime due to lower demand or substitutes.
            </p>

            <p>
              Shipkoo is also at its best in maintaining the shortest lead time possible. Lead time is
              the interval of time between the process initiation and completion. Several Lead-time
              approaches have been applied in Shipkoo, which include:
            </p>

            <ul>
              <li>
                Customer lead-time: the amount of time taken between order confirmation and order
                fulfillment
              </li>
              <li>
                Factory/production lead time: the amount of time it takes to build and ship a product if
                all the materials are available
              </li>
              <li>
                Material lead-time: the amount of time it takes to place an order with a supplier and
                receive it, from confirmed order to having it on hand
              </li>
              <li>
                Cumulative lead-time: the total amount of time it would take from confirmed order to
                delivery of the product if you had to order
              </li>
            </ul>

            <Image src={TruckImageSource} alt="cargo truck shipment sillhoute" />

            <p>
              Shipkoo has been able to maintain the shortest lead time possible as it can be seen in its
              partnership with{' '}
              <a href="https://www.zulily.com" target="#">
                Zulily
              </a>{' '}
              and{' '}
              <a href="https://drop.com/" target="#">
                Drop
              </a>{' '}
              companies. Shipkoo ensured that the companies get their orders quickly within the lowest
              time and cost possible. Initially, this was a challenge to them, but now this is
              considered a done and dusted issue. This is nothing but JIT technique and lead times that
              not only ensures that delivery time is minimized, but it also ensures lower costs are
              incurred.
            </p>
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare>  
          </div>
          <Clear></Clear>
        </BlogRow>
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;

